<template>
  <section>
    <section v-if="editing">
      <QuickEditActionsComponent
        v-if="quickEdit"
        @cancel="$emit('cancel')"
        @validate="$emit('validate')"
      ></QuickEditActionsComponent>
    </section>
    <section v-else class="actions-cell">
      <slot name="before"></slot>
      <ViewEditComponent
        class="d-none d-md-inline-block"
        :style="`${viewEdit ? '' : 'visibility: hidden'}`"
        @view="$emit('view')"
        @edit="$emit('edit')"
      ></ViewEditComponent>
      <CommentaireComponent
        class="d-none d-md-inline-block"
        v-if="comment"
        :value="commentValue"
        :disabled="commentDisabled"
        @click="$emit('comment')"
      ></CommentaireComponent>
      <DropdownComponent v-if="dropdown">
        <template #button-content>
          <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
        </template>
        <slot></slot>
      </DropdownComponent>
      <slot name="after"></slot>
    </section>
  </section>
</template>

<script>
import QuickEditActionsComponent from "./QuickEditActionsComponent.vue";
import CommentaireComponent from "./CommentaireComponent.vue";
import ViewEditComponent from "./ViewEditComponent.vue";
import DropdownComponent from "./DropdownComponent.vue";
export default {
  name: "TableActionsComponent",
  components: {
    QuickEditActionsComponent,
    CommentaireComponent,
    ViewEditComponent,
    DropdownComponent,
  },
  /**
   * editing = true pour basculer en mode édition (false par défaut)
   * commentValue = valeur du commentaire (s'affiche au survol)
   * commentDisabled = true pour désactiver le bouton (false par défaut)
   * noQuickEdit = true pour masquer les boutons d'édition en ligne
   * noComment = true pour masquer le bouton commentaire
   * noViewEdit = true pour masquer le bouton view/edit
   * noDropdown = true pour masquer le menu dropdown
   */
  props: [
    "editing",
    "commentValue",
    "commentDisabled",
    "noQuickEdit",
    "noComment",
    "noViewEdit",
    "noDropdown",
  ],
  computed: {
    quickEdit() {
      return this.noQuickEdit !== true && this.noQuickEdit !== "true";
    },
    comment() {
      return this.noComment !== true && this.noComment !== "true";
    },
    viewEdit() {
      return this.noViewEdit !== true && this.noViewEdit !== "true";
    },
    dropdown() {
      return this.noDropdown !== true && this.noDropdown !== "true";
    },
  },
};
</script>
