<template>
  <!-- TODO Masqué jusqu'à implémentation des exports -->
  <b-button-group v-if="false" class="d-none d-md-flex">
    <slot name="before"></slot>
    <b-button
      :class="classPrint"
      variant="outline-primary"
      @click="() => window.print()"
    >
      <b-icon-file-earmark-pdf></b-icon-file-earmark-pdf>
      Imprimer
    </b-button>
    <b-dropdown
      :toggle-class="classToggle"
      menu-class="z-index-2000"
      variant="outline-primary"
    >
      <template #button-content>
        <b-icon-file-earmark-spreadsheet-fill></b-icon-file-earmark-spreadsheet-fill>
        Exporter
      </template>
      <b-dropdown-item @click="$emit('exportExcel')">
        Microsoft Excel (XLSX)
      </b-dropdown-item>
      <b-dropdown-item @click="$emit('exportCsv')">
        Donn&eacute;es brutes (CSV s&eacute;parateur ";")
      </b-dropdown-item>
    </b-dropdown>
    <slot name="after"></slot>
  </b-button-group>
</template>

<script>
export default {
  name: "ExportButtonGroup",
  data: () => ({ window: document.defaultView }),
  computed: {
    classPrint() {
      if (this.$slots.before) {
        return "";
      }
      return "rounded-left";
    },
    classToggle() {
      if (this.$slots.after) {
        return "";
      }
      return "rounded-right";
    },
  },
};
</script>
