<template>
  <section>
    <CancelIcon
      ref="cancelButton"
      role="button"
      class="mr-1"
      style="font-size: 250%"
      @click="$emit('cancel')"
    ></CancelIcon>
    <b-tooltip
      :target="
        () =>
          $refs['cancelButton'] ? $refs['cancelButton'].$el.children[0] : null
      "
      triggers="hover"
      variant="primary"
    >
      Annuler
    </b-tooltip>
    <ValidateIcon
      ref="validateButton"
      role="button"
      style="font-size: 250%"
      @click="$emit('validate')"
    ></ValidateIcon>
    <b-tooltip
      :target="
        () =>
          $refs['validateButton']
            ? $refs['validateButton'].$el.children[0]
            : null
      "
      triggers="hover"
      variant="primary"
    >
      Valider
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "QuickEditActionsComponent",
};
</script>
