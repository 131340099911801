<template>
  <span>
    <b-button
      ref="viewButton"
      class="d-inline-block d-md-none action-button"
      variant="link"
      @click="onView"
    >
      <b-icon-eye></b-icon-eye>
    </b-button>
    <b-tooltip
      :target="() => $refs['viewButton']"
      triggers="hover"
      variant="primary"
    >
      Voir le d&eacute;tail
    </b-tooltip>
    <b-button
      ref="editButton"
      class="d-none d-md-inline-block action-button"
      variant="link"
      @click="onEdit"
    >
      <b-icon-pencil-square></b-icon-pencil-square>
    </b-button>
    <b-tooltip
      :target="() => $refs['editButton']"
      triggers="hover"
      variant="primary"
    >
      Modifier
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: "ViewEditComponent",
  methods: {
    onView() {
      return this.$emit("view", null);
    },
    onEdit() {
      return this.$emit("edit", null);
    },
  },
};
</script>
