<template>
  <b-dropdown
    right
    variant="link"
    no-caret
    toggle-class="action-button"
    menu-class="z-index-2000"
  >
    <template
      v-for="slotName in Object.keys(this.$scopedSlots)"
      #[slotName]="context"
    >
      <slot :name="slotName" v-bind="context"></slot>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: "DropdownComponent",
};
</script>
